<template>
    <div class="road_box">
        <div class="container main" id="ROADMAP">
            <div class="pic">
                <img src="../assets/images/672.png" alt="">
            </div>
            <div class="cont">
                <div class="title_conts">
                    <img src="../assets/images/roadmap.png" alt="">
                </div>
                <div class="card">
                    <div class="box">
                        <p>Phase 1: Meme</p>
                        <p>Phase 2: Vibe and HODL</p>
                        <p>Phase 3: Meme Takeover</p>
                    </div>
                </div>
            </div>
            <div class="pic">
                <img src="../assets/images/667.png" alt="">
            </div>
        </div>
        <div class="main text_main" style="margin-top: 60px;">
            <p>All jokes aside, here is a rough sketch of $SQUID path ahead. We dont wan’t</p>
            <p>to give everything away on day 1, Expect surprises along the way ;)</p>
        </div>
        <div class="card_list main">
            <!-- <div class="pic_1">
                <img src="../assets/images/road_pic1.png" alt="">
            </div> -->
            <div class="card">
                <div class="box">
                    <h3>Phase 1</h3>
                    <p>Launch</p>
                    <p>CoinGecko/Coinmark</p>
                    <p>etcap Listings</p>
                    <br>
                    <p>1,000+ Holders</p>
                    <p>Get $SQUID Trending</p>
                    <p>on twitter with our</p>
                    <p>memetic power</p>
                </div>
            </div>
            <div class="card">
                <div class="box">
                    <h3>Phase 2</h3>
                    <p>Community Partnerships</p>
                    <p>SQUID Times digital</p>
                    <p>newsletter</p>
                    <br>
                    <p>Formation of token gated</p>
                    <p>discord group, Poo</p>
                    <p>Palace, for holders, more</p>
                    <p>details tba</p>
                    <br>
                    <p>CEX Listings</p>
                    <p>10,000+holders</p>
                </div>
            </div>
            <div class="card">
                <div class="box">
                    <h3>Phase 3</h3>
                    <p>SQUID merch</p>
                    <p>SQUID Academy</p>
                    <p>SQUID Tools</p>
                    <p>T1 Exchange Listings</p>
                    <p>100,000+ holders</p>
                    <br>
                    <p>Meme Takeover</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MyBanner from './banner_an.vue'

export default {
    data() {
        return {

        }
    },
    components: {
        MyBanner
    }
}
</script>

<style lang="less" scoped>
.title_conts {
    margin-top: 10px;
}

.pile {
    position: absolute;
    left: 0;
    transform: translateX(-10%);
    width: 7%;
    top: 20%;

    img {
        width: 100%;
    }
}

.road_box {
    position: relative;
    background: url('../assets/images/roadmapbg.png') no-repeat;
    background-position: center -50px;
    background-size: 90% auto;
    margin-top: 30px;
    padding-top: 160px;
}

.banners {
    position: relative;
    margin-top: 80px;

    .emoji {
        width: 220px;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-97.5%);

        img {
            width: 100%;
        }
    }
}

.text_main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    p {
        width: 80%;
        font-size: 18px;
        color: rgba(255, 255, 255, .72);
        font-family: 'roboto';
        font-weight: normal;
        text-align: center;
    }
}


.pic_1 {
    position: absolute;
    left: 0;
    top: 40%;
    width: 15%;
}

.pic_1 img {
    width: 100%;
}

.card_list {
    display: flex;
    justify-content: space-around;
    margin-top: 80px;

    .card {
        position: relative;
        margin-bottom: 20px;
        width: 30%;
        padding: 15px;
        box-sizing: border-box;
        /* Note: backdrop-filter has minimal browser support */
        border-radius: 12px;
        color: #fff;
        font-style: normal;
        overflow: hidden;
        z-index: 99;

        .card::after {
            top: 0;
            left: 0;
            content: '';
            position: absolute;
            border: 5px solid;
            border-image: linear-gradient(to right bottom, #892449, rgba(0, 0, 0, 0)) 1;
            width: calc(100% - 10px);
            height: calc(100% - 10px);
        }

        .box {
            width: 100%;
            height: 100%;
        }

        h3 {
            text-align: center;
            margin-bottom: 15px;
            font-weight: 600;
            font-size: 18px;
            left: 50%;
            width: 100%;
            font-family: 'unb_lig';
            color: #FFFFFF;
        }

        p {
            font-size: 18px;
            font-family: 'hwm' !important;
            margin-top: 5px;
            width: 100%;
            color: #fff;
            text-align: left;
        }
    }
}

.container {
    display: flex;
    justify-content: center;

    .cont {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0 60px;

        .bg_roa {
            position: absolute;
            width: 100%;

            img {
                width: 100%;
            }
        }

        .title {
            margin-bottom: 50px;
            text-align: center;

            img {
                width: 70%;
            }
        }
    }
}

.card {
    margin-bottom: 20px;
    width: 100%;
    padding: 15px 5px 5px;
    box-sizing: border-box;
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 12px;
    // backdrop-filter: blur(3px);
    // border: 3px solid #477532;
    // background: rgba(255, 255, 255, 0);
    color: #fff;
    font-style: normal;
    font-size: 22px;
    z-index: 99;
    position: relative;
    overflow: hidden;

    &&::after {
        top: 0;
        left: 0;
        content: '';
        position: absolute;
        border: 5px solid;
        border-image: linear-gradient(to right bottom, #892449, rgba(0, 0, 0, 0)) 1;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
    }

    .box {
        color: #fff;
        text-align: center;
        padding: 20px 30px;
    }

    p {
        font-family: 'hwm' !important;
    }
}

.pic {
    width: 200px;
    display: flex;
    align-items: center;
    // padding-top: 10%;

    img {
        width: 100%;
    }
}

@media screen and (max-width:1000px) {
    .card_list {
        flex-direction: column;
        align-items: center;

        .card {
            width: 50%;
            margin: 15px 0;
        }
    }
}

@media screen and (max-width:700px) {
    .container {
        // margin-top: 80px;
    }

    .pic {
        display: none;
    }

    .container .cont .title {
        margin-bottom: 20px;
    }

    .text_main p {
        text-align: left !important;
        margin-bottom: 5px;
    }
}

@media screen and (max-width:500px) {
    .card_list .card {
        width: 95%;
    }

    .title_conts {
        width: 85%;
    }

    .title_conts img {
        width: 100%;
    }
}
</style>